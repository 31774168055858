<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-button
        class="btn btn-gradient-primary mb-1 mr-50 col-md-2"
        @click="$router.go(-1)"
      >
        <i class="fas fa-arrow-left mr-25" /> {{ $t('returnToList') }}
      </b-button>
      <b-button
        class="btn btn-gradient-success mb-1 ml-25 mr-50 col-md-2"
        @click="Submit()"
      >
        {{ $t('saveData') }}
      </b-button>

      <b-row class="match-height">
        <b-col md="6">

          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('details') }}
              </h3>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-for="h-paecelCode"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      <span class="text-danger">*</span> {{ $t('parcelCode') }}  :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Parcel Code"
                      rules="required"
                    >
                      <v-select
                        id="h-paecelCode"
                        v-model="parcelCode"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :state="errors.length > 0 ? false:null"
                        label="postnumber"
                        :options="parcelList"
                        class="w-100 mr-1"
                        @input="Checkpost(parcelCode)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-remark"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      {{ $t('remarks') }} :
                    </template>
                    <b-form-textarea
                      id="h-remark"
                      v-model="remark"
                      type="text"
                      class="mb-2"
                    />
                    <app-timeline>
                      <app-timeline-item
                        v-if="come_thaiwarehouse"
                        :title="`${come_thaiwarehouse} : `+$t('arrivedThaiWarehouse')"
                        variant="success"
                      />

                      <app-timeline-item
                        v-if="out_chinesewarehouse"
                        :title="`${out_chinesewarehouse} : `+$t('exitWarehouse')"
                        variant="success"
                      />

                      <app-timeline-item
                        v-if="come_chinesewarehouse"
                        :title="`${come_chinesewarehouse} : `+$t('arrivedChinaWarehouse')"
                        variant="success"
                      />
                    </app-timeline>
                  </b-form-group>

                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>

        <b-col md="6">
          <div
            class="card"
          >
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('attachmentList') }}
              </h3>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-for="h-file1"
                    label-cols="3"
                    label-cols-lg="3"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      <span class="text-danger">*</span> {{ $t('attachedFile') }} :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="File"
                      rules="required"
                    >
                      <b-form-file
                        id="h-file1"
                        v-model="fileadmins"
                        accept=".jpg, .png, .jpeg"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        :state="errors.length > 0 ? false:null"
                        @change="fileImage"
                      />
                      <div class="mt-2 d-flex justify-content-left">
                        <span
                          v-for="imageOr in fileadmin"
                          :key="imageOr"
                          class="preview-image-containerv2 mr-2 d-flex justify-content-center"
                        >
                          <img
                            :src="imageOr"
                            alt="Preview"
                            class=""
                            @click="showPicture(imageOr)"
                          >
                          <div class="preview-image-overlays p-0">
                            <i
                              class="fas fa-times-circle cursor-pointer"
                              @click="deleteImageorder(imageOr)"
                            />
                          </div>
                          <vue-image-lightbox-carousel
                            ref="lightbox"
                            :show="showLightbox"
                            :images="images"
                            :show-caption="false"
                            @close="closeBox()"
                          />
                        </span>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

          </div>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
  BFormFile,
  BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormTextarea,
    vSelect,
    BFormFile,
    BButton,
    AppTimeline,
    AppTimelineItem,
    VueImageLightboxCarousel,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      images: [],
      showLightbox: false,
      required,
      file: null,
      parcelCode: null,
      remark: null,
      parcelList: [
        {
          value: '1',
          text: '1',
        },
        {
          value: '2',
          text: '2',
        },
        {
          value: '3',
          text: '3',
        },
      ],
      come_thaiwarehouse: null,
      out_chinesewarehouse: null,
      come_chinesewarehouse: null,
      fileadmin: [],
      fileadmins: null,
    }
  },
  mounted() {
    this.getPostnumber()
  },
  methods: {
    Submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$bvModal.msgBoxConfirm('ต้องการบันทึกสินค้าไม่มีเจ้าของ?', {
            title: 'ยืนยันข้อมูล',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'warning',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }).then(value => {
            if (value) {
              if (!this.fileadmin.length) {
                this.SwalError('กรุณาแนบไฟล์')
              } else {
                const obj = {
                  remark: this.remark,
                  fileadmin: this.fileadmin,
                  come_chinesewarehouse: this.come_chinesewarehouse,
                  out_chinesewarehouse: this.out_chinesewarehouse,
                  come_thaiwarehouse: this.come_thaiwarehouse,
                  active: true,
                  // eslint-disable-next-line no-underscore-dangle
                  id_postnumber: this.parcelCode._id,
                  postnumber: this.parcelCode.postnumber,
                  lot_name: this.parcelCode.lot_name,
                  lot_order: this.parcelCode.lot_order,
                  lot_id: this.parcelCode.lot_id,
                  statusconfirm: 1,
                }
                this.$http.post('/Goodsconfirm/storeData', obj)
                  .then(() => {
                    this.Success('บันทึกข้อมูลเรียบร้อย')
                    this.$router.push({ name: 'admin-goodsconfirm' })
                  })
                  .catch(err => {
                    this.SwalError(err)
                  })
                // console.log(this.parcelCode)
                // console.log(obj)
              }
            }
          })
        }
      })
    },
    async getPostnumber() {
      try {
        const { data: res } = await this.$http.get('/Goodsconfirm/getpostnumber')
        this.parcelList = res
        console.log(res)
      } catch (err) {
        console.log(err)
      }
    },
    Checkpost(data) {
      if (data) {
        this.come_chinesewarehouse = data.come_chinesewarehouse
        this.out_chinesewarehouse = data.out_chinesewarehouse
        this.come_thaiwarehouse = data.come_thaiwarehouse
      } else {
        this.come_chinesewarehouse = null
        this.out_chinesewarehouse = null
        this.come_thaiwarehouse = null
      }
    },
    fileImage(event) {
      this.fileadmins = event.target.files
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.fileadmins.length; i++) {
        const filepath = this.fileadmins[i]

        const reader = new FileReader()
        reader.onload = e => {
          this.fileadmin.push(e.target.result)
        }
        reader.readAsDataURL(filepath)
      }
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: picture }
      this.images.push(img)
      // this.$swal({
      //   imageUrl: picture,
      //   imageWidth: 400,
      //   imageHeight: 400,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: 'ปิด',
      // })
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    deleteImageorder(index) {
      this.$bvModal.msgBoxConfirm('คุณต้องการลบไฟล์แนบใช่หรือไม่?', {
        title: 'ยืนยันการลบข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(value => {
        if (value) {
          // this.delete_path = []

          const indexToRemove = this.fileadmin.indexOf(index)
          if (indexToRemove !== -1) {
            this.fileadmin.splice(indexToRemove, 1)
          }
        } else {
          console.log('เกิดข้อมูลผิดพลาด')
        }
      })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showAdd() {
      this.$bvModal.show('add-addr')
    },
    showModal() {
      this.name = null
      this.phone = null
      this.address = null
      this.province = null
      this.district = null
      this.subdistrict = null
      this.postalcode = null
      this.$bvModal.show('modal-1')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
